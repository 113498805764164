import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import { useVehicleByRegNo } from '../hooks/use-vehicle-by-regno'
import { device } from './device'
import { RegNoContext } from '../context/regno'

const formatDate = (date) => {
  try {
    const dateFormatter = new Intl.DateTimeFormat('nb', {
      year: 'numeric',
      month: 'long',
      day: '2-digit',
    })
    const [{ value: day }, , { value: month }, , { value: year }] = dateFormatter.formatToParts(
      new Date(date)
    )
    return `${day}. ${month} ${year}`
  } catch (e) {
    return '- Ikke tilgjengelig -'
  }
}

const HeroOption = styled.div`
  display: block;
  //height: 15%;
  width: 100%;
  justify-content: center;
  padding: 0.9rem 1rem;
  margin-top: -11px;
  color: white;
  z-index: 1;
  background: #222222;
  @media only screen and ${device.tablet} {
    max-width: 500px;
  }

  h5 {
    margin-top: -0.2rem;
    margin-bottom: 1rem;
    font-weight: 700;
  }

  form {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-gap: 2px;
    @media ${device.tabletMaxL} {
      grid-template-columns: 1fr;
      grid-gap: 5px;
    }
  }

  input {
    font-size: 1rem;
    font-family: inherit;
    display: block;
    width: 100%;
    padding: 0.5rem;
    border: none;
    z-index: 1;
    border-radius: 2px;
    text-transform: uppercase;
    outline: 2px solid black;
    outline-offset: -3.5px;
  }

  #searchButton {
    display: block;
    width: 100%;
    border-radius: 2px;
    color: white;
    background: var(--primary-color);
    transition: color 0.25s ease-out;
    font-size: 100%;
    padding: 0.5rem 1rem;
    border: none;
    z-index: 1;

    :disabled {
      color: rgba(255, 255, 255, 0.5);
    }
  }
`

const AutoComplete = styled.div`
  position: relative;
  display: flex;
  flex-flow: column;
  justify-content: center;
`

const PkkResponse = styled.div`
  display: hidden;
  position: absolute;
  width: calc(100% + 2rem);
  top: 2rem;
  left: -1rem;
  padding: 14px;
  background: #222222;
  @media ${device.tabletMaxL} {
    top: 4.5rem;
  }
  @media (min-width: 1433px) {
    padding: 16px;
  }
  h5 {
    margin-top: 0;
    font-size: 15px;
  }
`

const ButtonGroup = styled.div`
  display: flex;
`

const Button = styled.button`
  flex: 1;
  font-size: 0.9rem;
  padding: 0.4rem;
  border: 1px solid white;
  color: white;
  background: transparent;
  margin-right: 1.5rem;
`

const PrimaryButton = styled.button`
  flex: 1;
  font-size: 0.9rem;
  padding: 0.4rem;
  border: 1px solid var(--primary-color);
  color: white;
  background: var(--primary-color);
`

const NextPkkHeroOption = ({ orderButtonClicked }) => {
  const [error, setError] = useState('')
  const [showResults, setShowResults] = useState(false)
  const { regNo, setRegNo, vehicle, setVehicle } = useContext(RegNoContext)
  const { searching: searchingVehicle, search: searchVehicle } = useVehicleByRegNo()

  const getPkk = async (e) => {
    e.preventDefault()
    setError('')
    setVehicle(undefined)

    const vehicle = await searchVehicle(regNo)
    setShowResults(true)

    if (vehicle.kjennemerke) {
      setVehicle(vehicle)
    } else {
      setError(`Fant ingen bil med reg. nr. ${regNo}`)
    }
  }

  const onRegNoChange = (e) => setRegNo(e.target.value.toUpperCase())
  const disableSearch = searchingVehicle || regNo?.length === 0

  return (
    <HeroOption id="pkk" style={{ zIndex: '0' }}>
      <h5>Når er neste EU-kontroll?</h5>
      <AutoComplete>
        <form onSubmit={getPkk}>
          <input
            type="text"
            name="regno"
            value={regNo}
            maxLength={7}
            placeholder="Regnr."
            onChange={onRegNoChange}
          />
          <button id="searchButton" type="submit" disabled={disableSearch ? 'disabled' : ''}>
            Sjekk og bestill time
          </button>
        </form>

        <PkkResponse>
          {searchingVehicle && <p>Søker etter {regNo}...</p>}

          {error && <p>{error}</p>}

          {showResults && vehicle ? (
            <div>
              <h5>
                Neste kontrollperiode er{' '}
                <strong>
                  <time>{formatDate(vehicle.nestePKK)}</time>
                </strong>
              </h5>
              <p style={{ fontSize: '13px' }}>
                Sist godkjent{' '}
                <time>
                  <strong>{formatDate(vehicle.sistPKKgodkj)}</strong>
                </time>
              </p>
              <p style={{ fontSize: '13px' }}>
                {vehicle.kjennemerke} - {vehicle.merkeNavn} {vehicle.modellbetegnelse}{' '}
                {vehicle.regAAr}
              </p>

              <ButtonGroup>
                <Button
                  type="button"
                  onClick={() => {
                    setVehicle(undefined)
                    setShowResults(false)
                    setRegNo([])
                  }}
                >
                  Lukk
                </Button>
                <PrimaryButton
                  type="button"
                  onClick={() => {
                    setShowResults(false)
                    orderButtonClicked()
                  }}
                >
                  Bestill EU-kontroll
                </PrimaryButton>
              </ButtonGroup>
            </div>
          ) : null}
        </PkkResponse>
      </AutoComplete>
    </HeroOption>
  )
}

export default NextPkkHeroOption
